<template>
  <div class="Loading">
    <div class="LoadingIcon">
      <div class="Radius"></div>
      <iconic :name="app" :options="{ original: true }" />
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      app: function () {
        return this.$route.query.app || "autologin";
      },
    },
  };
</script>

<style lang="scss">
  $size: 200px;
  .Loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .LoadingIcon {
      width: $size;
      height: $size;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .Radius {
      width: $size;
      height: $size;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      animation: rotate 1s linear infinite;
      border: solid 4px $primary-color;
      border-right: solid 4px transparent;
    }
  }
</style>
